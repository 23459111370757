// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-company-js": () => import("./../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-people-js": () => import("./../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-basic-js": () => import("./../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-basic-sections-js": () => import("./../src/templates/basicSections.js" /* webpackChunkName: "component---src-templates-basic-sections-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

